body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

#gmap {
	height: 320px;
}

.navbar-header {
	padding: 20px 0;
}

.navbar-collapse.in {
	overflow-y: initial;
}

.dropdown-menu.active {
	display: block;
}

@media screen and (max-width: 768px) {
	.dropdown-menu > li > a {
		white-space: normal;
	}
}

.bg-purple {
	background: linear-gradient(90deg,#9000f8 0,#d836cb);
}

.margin-40 {
	margin: 4rem auto;
}

.margin-bottom-20 {
	margin-bottom: 2rem;
}

.margin-bottom-40 {
	margin-bottom: 4rem;
}

.no-margin {
	.wpb_content_element{
		margin: 0 !important;
	}
}

.padding-top-0 {
	& > div {
		& > div {
			padding-top: 0 !important;
		}
	}
}


.thin {
	font-weight: lighter;
	font-size: 3.5rem;
	color: $white
}

.purple {
	color: $purple !important;
}

.white {
	color: $white !important;
}

header.banner .header-title>h1 {
	@media screen and(max-width: 767px) {
		font-size: 3rem !important;
	}
}

h2 {
	font-size: 2.6rem;
	font-weight: bold;
	@media screen and(max-width: 767px) {
		font-size: 2rem;
		line-height: 2.8rem;
	}
}

.border-right-white {
	border-right: 1px solid $white;

	@media screen and(max-width: 767px) {
		border-right: none;
	}
}

a {
	color: $black;
	font-weight: bold;
	transition: .5s ease-in-out;

	&:hover {
		text-transform: none;
		text-decoration: none;
		color: $black;
	}
}

.green-line {
	background: linear-gradient(to right, #009245 0%,#c8e82d 100%,#c8e82d 100%);
	height: .8rem;
	border-radius: 10px;
	width: 30rem;
	margin-top: 2rem;
}

.purple-line {
	background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);
	height: .8rem;
	border-radius: 10px;
	width: 30rem;
	margin-top: 2rem;
}

i {
	transition: .5s ease-in-out;
}

.align-center {
	display: flex;
	align-items: center !important;
	justify-content: center;
}

.h4 {
	color: $white;
	font-weight: 700;
	font-size: 1.4rem;
	text-transform: uppercase;
	margin-bottom: 2rem;
}