.blog-item {
	cursor: pointer;
	margin-bottom: 3rem;

	& > div {
		border: 1px solid #E6E6E6;
		padding: 2rem;
		transition: .5s ease-in-out;
		height: 100%;
		width: 100%;

		h3 {
			margin-top: 0;
			color: $green;
			text-transform: uppercase;
			font-size: 1.8rem;
			font-weight: bold;
		}

		a {
			margin-top: 2rem;
		}
		&:hover {
			box-shadow: 0 10px 20px rgba(144, 0, 248, .16);
		}
	}
}