.blog-home {
	padding: 2rem 0;

	& > div:first-of-type {
		position: relative;
		margin-bottom: 5rem;

		h2 {
			margin-bottom: 5rem;
		}
	}

	.blog-access {
		text-align: right;
		position: relative;

		a {
			text-transform: uppercase;
			font-size: 2rem;
			font-weight: 300;
		}

		& > div {
			position: absolute;
			right: 15px;
			bottom: -15px;
		}
	}
}