footer {
	background-color: $black;

	& > div:first-of-type {
		padding: 8rem 0;
		border-bottom: 1px solid $white;

		img {
			margin-bottom: 2rem;
		}

		p {
			font-size: 1.4rem;
			font-weight: 400;
			color: #FFF !important;

			& > a {
				font-size: 1.4rem;
				font-weight: 400;
				color: #FFF !important;

				&:hover {
					color: #FFF !important;
					text-decoration: none;
				}
			}
		}

		ul {
			list-style-type: none;
			padding: 0;

			> li {
				& > a {
					color: $white;
					font-size: 1.4rem;
					font-weight: 400;
				}
			}
		}

		@media all and (max-width: 767px){
			padding-left: 4rem;
		}
	}
	& > div:last-of-type {
		padding: 2rem 0 8rem;
		& > div {
			padding: 0 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.copyright {
				& > p {
					color: $white;
					font-weight: 400;
					font-size: 1.2rem;
					margin: 0;
				}
			}

			.legal-page {
				& > a {
					font-size: 1.2rem;
					font-weight: 400;
					color: $white;

					&:hover {
						color: $white;
						text-decoration: none;
					}

					&:first-of-type {
						margin-right: 2rem;
					}
				}
			}

			.social-link {
				& > a {
					& > i {
						color: $white;

						&:hover {
							color: $green;
						}
					}

					&:first-of-type {
						margin-right: 1rem;
					}
				}
			}

			@media all and (max-width: 767px) {
				flex-direction: column;
			}
		}
	}
}