.news-item {
	cursor: pointer;
	margin-bottom: 2rem;

	& > div {
		height: 100%;
		width: 100%;
		transition: .5s ease-in-out;
		border: 1px solid #E6E6E6;

		& > div:first-of-type {
			position: relative;
			overflow: hidden;
			height: 16rem;

			& > img {
				max-width: 100%;
				height: auto;
				position: absolute;
				width: 100%;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);

			}
		}

		& > div:last-of-type {
			padding: 2rem;
			display: flex;
			justify-content: space-around;
			flex-direction: column;

			h3 {
				color: $purple;
				text-transform: uppercase;
				font-size: 1.8rem;
				font-weight: bold;
			}

			& > a {
				margin-top: 2rem;
			}
		}

		&:hover {
			box-shadow: 0 10px 20px rgba(144, 0, 248, .16);
		}
	}
}