header.banner {
	background-size: cover !important;
	background-color: transparent;
	transition: all 1s;

	.navbar-default .navbar-toggle .icon-bar {
		background: $white;
	}

	.top-menu {
		background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);
		height: 4rem;


		@media all and (max-width: 991px) {
			padding: 0 15px;
		}

		& > div {
			height: 100%;

			& > div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;

				p {
					margin: 0;
					color: $white;

					@media all and (max-width: 991px) {
						display: none;
					}
				}

				.top-menu-social {
					margin-right: 3rem;

					i {
						color: $white;
						font-size: 1.6rem;

						&:first-of-type {
							margin-right: .5rem;
						}
					}
				}

				.top-menu-contact {
					& > a {
						color: $white;

						&:first-of-type {
							margin-right: 1rem;
						}

						i {
							color: $white;
							margin-right: .5rem;
						}
					}
				}

				& > div {
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.container-menu-top {
		height: 100%;
		margin-top: 1rem;
		position: relative;
	}

	&.is_stuck {
		background-color: rgba(255, 255, 255, 0.95);
	}

	a.brand {
		margin-top: 1.5rem;
		display: inline-block;
		//position: relative;

		@media(max-width: $grid-float-breakpoint) {
			display: none;
		}

		img {
			height: auto;
			width: 10rem;
		}

		svg {
			width: 70px;
			height: auto;
		}
	}

	.logo-mobile {

		position: relative;
		display: inline-block;
		height: auto;
		margin-left: 15px;

		@media(min-width: $grid-float-breakpoint) {
			display: none;
		}

		svg {
			width: 70px;
			height: auto;
		}
	}

	button.navbar-toggle {

		border: none;
		background: transparent !important;

		&:hover {
			background: transparent !important;
		}

		.icon-bar {
			width: 22px;
			transition: all 0.2s;
		}

		.top-bar {
			transform: rotate(45deg);
			transform-origin: 10% 10%;
		}

		.middle-bar {
			opacity: 0;
		}

		.bottom-bar {
			transform: rotate(-45deg);
			transform-origin: 10% 90%;
		}

		&.collapsed {
			.top-bar {
				transform: rotate(0);
			}

			.middle-bar {
				opacity: 1;
			}

			.bottom-bar {
				transform: rotate(0);
			}
		}
	}

	.navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border: none;
	}

	nav {
		position: relative;

		ul.nav {

			@media(min-width: $grid-float-breakpoint) {
				position: absolute;
				right: 0;
				top: 25px;

				.dropdown:hover .dropdown-menu {
					display: block;
				}
				.dropdown-menu {
					margin-top: 0px;
				}
			}

			@media(max-width: $grid-float-breakpoint) {
				padding: 0 15px;
			}

			li {
				a {
					//font-family: $headings-font-family;
				}
			}

			.active {
				border-bottom: 2px solid $purple;
			}

			& > li {
				margin-right: 2rem;
				border-bottom: 1px solid transparent;
				transition: .5s ease-in-out;

				&:last-of-type {
					margin-right: 0;
				}

				&.btn-search {
					&:hover {
						border-bottom: 1px solid transparent;
					}

					> a {
						padding: 10px;

						> i {
							font-size: 30px;
						}
					}
				}

				@media(min-width: $grid-float-breakpoint) {
					&:hover {
						border-bottom: 2px solid $purple;

					}

					& > ul {
						background-color: rgba(255, 255, 255, 1) !important;
						border-top: 5px solid $purple !important;
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;

						& > li.active {
							border: none;

							& > a {
								background-color: transparent;
								border: none;

								&:hover {
									background-color: transparent !important;
								}
							}
						}
					}
				}

				> a {
					font-weight: 400;
					font-size: 14px;
					text-transform: uppercase;
					cursor: pointer;
					padding: 1rem 0;
				}

				> ul {
					border: 0 none;
					box-shadow: none;
				}
			}
		}
	}

	.header-title {
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);

		& > h1 {
			font-size: 4.5rem;
			color: $white;
		}

	}
}

@media(max-width: $grid-float-breakpoint) {
	.navbar-collapse {
		background-color: #3e3e3e !important;
	}
}

@media(min-width: $grid-float-breakpoint) {
	.dropdown-menu > li > a {
		color: $black !important;
		transition: .4s ease-in-out;

		&:before {
			content: ' ';
			width: 8px;
			position: absolute;
			border-bottom: 1px solid $purple;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			color: $purple;
			transition: .4s ease-in-out;
			opacity: 0;
		}

		&:hover {
			transform: translateX(10px);

			&:before {
				opacity: 1;

			}

			color: $purple !important;
			background-color: transparent !important;
		}

	}
}