.societes {
	background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);
	padding: 4rem 4rem;
	margin-top: 4rem;

	h2 {
		font-size: 38px;
		color: $white;
	}

	.societes-container {
		margin-top: 4rem;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.societe-item {
			width: 25%;
			margin-bottom: 40px;

			& > p {
				text-align: left;
				color: $white;
				margin: 0;

				&:first-of-type {
					text-transform: uppercase;
					font-size: 18px;
				}

				&:nth-child(2) {
					font-size: 14px;
				}

				&:last-of-type {
					font-weight: bold;
					font-size: 27px;
				}
			}

			@media all and (max-width: 991px) {
				width: 50%;
				max-width: initial;

			}
		}


		@media all and (max-width: 767px) {
			justify-content: center;

			.societe-item {
				width: 100%;
				max-width: initial;

			}
		}
	}
}