#back-to-top {
	position: fixed;
	bottom: 100px;
	right: 20px;
	z-index: 9999;
	width: 6rem;
	height: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);;
	color: #444;
	cursor: pointer;
	border: 0;
	border-radius: 5px;
	text-decoration: none;
	transition: opacity 0.4s ease-out;
	opacity: 0;
	visibility: hidden;

	& > i {
		color: $white;
		font-size: 2rem;
	}

	&:hover {
		background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);
	}
}

#back-to-top.show-scroll {
	opacity: 1;
	visibility: visible;
}