.values {
	background: linear-gradient(90deg,#9000f8 0,#d836cb);
	padding: 100px 0;
	p {
		color: $white;
	}

	p.h2 {
		margin-bottom: 50px;
		font-weight: 700;
		text-transform: uppercase;
	}

	@media all and (max-width: 991px){
		p.h2 {
			text-align: center;
			margin-bottom: 3rem;
		}
	}

	.our-values {
		.values-item {

			& > div {
				display: flex;

				& > div:first-of-type {
					margin-right: 25px;
				}

				& > div:last-of-type {
					p, h3  {
						color: $white;
					}
				}
			}

			@media all and (max-width: 991px) {
				margin-bottom: 3rem;


				& > div {
					flex-direction: column;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	.our-numbers {
		margin: 0 auto;

		.numbers-item {
			& > div {
				display: flex;
				& > div:first-of-type {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 2rem;
					& > p {
						font-size: 65px;
						font-weight: bold;
					}
				}

				& > div:last-of-type {
					p, h3  {
						color: $white;
					}
				}
			}

			@media all and (max-width: 991px) {
				margin-bottom: 3rem;

				& > div {
					flex-direction: column;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

}