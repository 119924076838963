// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

    .wpcf7-validation-errors, .wpcf7-mail-sent-ng, .wpcf7-acceptance-missing {
  background-color: #f2dede;
  border-color: #eed3d7 !important;
  color: #b94a48;
  clear: both;
  margin: 15px 0 0;
  overflow: hidden;
  padding: 8px 10px;
}


.wpcf7-not-valid-tip {
  color: #b94a48 !important;
}

    .wpcf7-mail-sent-ok {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
  clear: both;
  margin: 15px 0 0;
  overflow: hidden;
  padding: 10px;
}

    // .wpcf7-not-valid-tip { border: 0; color: #b94a48; font-size: 1em; left: 5px; padding: 0 2px; position: absolute; top: 7px; width: 80%; z-index: 100; }
    span.required {
  color: #b94a48;
}

.form-group {
  margin-bottom: 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    width: 100% !important;

    & > span {
      width: 17rem;
    }
  }
}

.color-gris {
  ::-webkit-input-placeholder {
    color: $gray-light;
    font-weight: 200;
    font-size: 16px;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: $gray-light;
    font-weight: 200;
    font-size: 16px;

  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: $gray-light;
    font-weight: 200;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    color: $gray-light;
    font-weight: 200;
    font-size: 16px;
  }
}

.wpcf7-list-item {
  width: 100%;
  & > label {
    width: 100%;
    & > span {
      font-size: 12px;
      font-weight: 200;
      color: $gray-light;
      margin-left: 2rem;
      width: 100%;
    }
  }
}

#wpcf7-f7267-p7264-o1,#wpcf7-f7268-p7269-o1 {
  input, textarea {
    color: $gray;
  }
}