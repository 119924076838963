.job-list {

	h2 {
		margin-bottom: 40px;
		text-transform: uppercase;
	}
}

.job-item {
	cursor: pointer;
	margin-bottom: 3rem;

	& > div {
		border: 1px solid #E6E6E6;
		padding: 4rem;
		transition: .5s ease-in-out;
		height: 100%;
		width: 100%;

		& > div {
			display: flex;
			flex-direction: column;

			h3 {
				margin-top: 0;
				color: $green;
				text-transform: uppercase;
				font-size: 1.8rem;
				font-weight: bold;
				margin-bottom: 2rem;
			}

			a {
				margin-top: 2rem;
			}
		}

		&:hover {
			box-shadow: 0 10px 20px rgba(0, 0, 0, .16);
		}
	}
}