.sticky-header {
	position: fixed;
	top: 160px;
	display: flex;
	flex-direction: column;
	z-index: 9999;
	right: 20px;

	@media all and (max-width: 991px) {
		display: none;
	}

	> .sticky-item {
		background: linear-gradient(to right, #9000f8 0%,#d836cb 100%);
		width: 7rem;
		height: 7rem;
		position: relative;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 3rem;
		padding: 1rem;

		& > a {
			display: block;
			& > img {
				width: 100%;
				height: 100%;
			}
		}

		& > span {
			opacity: 0;
			position: absolute;
			top: 20%;
			width: 10rem;
			left: -155%;
			text-align: center;
			transition: .5s ease-in-out;
			font-size: 12px;
			padding: .5rem 1rem;
			border-radius: 4px;
			background-color: $gray-lighter;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			& > span {
				opacity: 1;
			}
		}
	}
}