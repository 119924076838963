.associes-listing {
	& > .associes-items {
		margin-bottom: 30px;
		max-width: 300px;

		& > .associes-items-img {
			position: relative;
			overflow: hidden;
			height: 247.5px;

			& > img {
				position: absolute;
				min-width: 100%;
				min-height: 100%;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}

			& > .associes-items-overlay {
				height: 100%;
				width: 100%;
				transform: translateY(-101%);
				transition: .5s ease-in-out;
				display: flex;
				justify-content: center;
				flex-direction: column;
				border-bottom: 4px solid $purple;

				p {
					font-weight: bold;
				}

				& > .overlay-title {
					font-size: 12px;
				}

				& > .overlay-text {
					font-size: 16px;
				}


				.arrow-up {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;

					border-bottom: 5px solid $purple;
				}


			}

			&:hover {
				& > .associes-items-overlay {
					transform: translateY(0);
					background-color: rgba(255, 255, 255, .8);
				}
			}

		}

		& > .associes-items-info {
			margin-top: 1rem;

			& > p:first-of-type {
				color: $purple;
				font-size: 14px;
				margin-bottom: .5rem;
			}

			& > p {
				&:nth-child(2) {
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 1rem;
				}
			}
		}
	}
}