.arrow-purple {
	&:after {
		font-family: FontAwesome;
		content: '\f054 ';
		color: $purple;
		margin-left: 1rem;
	}
}

.arrow-white {
	color: $black;

	&:after {
		font-family: FontAwesome;
		content: '\f054 ';
		color: $white;
		margin-left: 1rem;
	}
}

.arrow-green {
	&:after {
		font-family: FontAwesome;
		content: '\f054 ';
		color: $green;
		margin-left: 1rem;
	}
}

.button-white {
	border-radius: 5px;
	background-color: white;
	color: $purple;
	text-align: left;
	padding: 1rem 8rem 1rem 1rem;
	text-transform: uppercase;
	font-weight: bold;
}

.button-purple {
	border-radius: 5px;
	background-color: $purple;
	color: $white;
	text-align: left;
	padding: 1rem 8rem 1rem 1rem;
	text-transform: uppercase;
	font-weight: bold;
}

@media(min-width: $grid-float-breakpoint) {
	.news-button {
		background: linear-gradient(90deg, #9000f8 0, #d836cb);
		color: $white;
		border-radius: 5px;
		padding: 0 2rem;
		margin-left: 3rem;
	}
}

.btn-file {
	background-color: $purple;
	color: $white;
	transition: .5s;
	height: 100%;
	border-radius: 5px;

	&:hover {
		background-color: $purple;
		color: white;
	}

	& > span {
		& > span {
			position: absolute;
			font-size: 1.2rem;
			top: 34px;
			left: -108px;
			text-transform: none;
		}
	}
}

.file-input {
	display: none !important;
}

.inputTxt {
	background-color: transparent !important;
	border: none;
	box-shadow: none;
	color: $white;
}